import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import SingleObject from './components/SingleObject';
import SceneContainer from './components/Scene';
import Buildings from './components/Buildings';
import Navigation from './components/Navigation';
import LoadingIndicator from './components/LoadingIndicator';
import NoMatch from './components/NoMatch';
import ObjectTable from './components/ObjectTable';
import { loadObjects } from './helpers/helpers';
import ModalProvider from './contexts/ModalContext';

import './App.scss';
import allowedRoutes from './data/allowedRoutes.json';

ReactGA.initialize('UA-183931554-3', {
  testMode: process.env.NODE_ENV === 'test',
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchObjects = async () => {
      const json = await loadObjects();
      if (json) {
        const data = json.map((item) => {
          const { status, monthlyFee, price, factsheetUrl, ...rest } = item;

          if (status === 'BOOKED' || status === 'SOLD') {
            return {
              status,
              price: null,
              monthlyFee: null,
              factsheetUrl: null,
              ...rest,
            };
          } else {
            return item;
          }
        });

        setItems(data);
      }
      setLoading(false);
    };
    fetchObjects();
  }, []);

  return (
    <Router>
      <ModalProvider>
        <div className="App">
          {loading && <LoadingIndicator />}
          {!loading && (
            <>
              <Navigation />
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => {
                    return (
                      <div className="main-wrapper">
                        <Buildings />
                      </div>
                    );
                  }}
                />
                <Route
                  path="/hus/:building/:sceneIndex"
                  render={(props) => {
                    const { building, sceneIndex } = props.match.params;
                    return allowedRoutes.buildings.includes(building) &&
                      allowedRoutes.scenes.includes(sceneIndex) ? (
                      <div className="main-wrapper">
                        <SceneContainer items={items} />
                      </div>
                    ) : (
                      <NoMatch />
                    );
                  }}
                />
                <Route
                  path="/prislista/"
                  render={(props) => {
                    return (
                      <ObjectTable
                        items={items}
                        totalItems={items.length}
                        statusState={{ showStatus: null, setShowStatus: null }}
                      />
                    );
                  }}
                />
                <Route
                  path="/objekt/:objectId"
                  render={(props) => {
                    return (
                      <SingleObject
                        items={items}
                        objectId={props.match.params.objectId}
                      />
                    );
                  }}
                />
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </>
          )}
        </div>
      </ModalProvider>
    </Router>
  );
}

export default App;
