import React, { useState } from 'react';
import NoMatch from './NoMatch';
import BackLink from './BackLink';
import LoadingIndicator from './LoadingIndicator';
import { formatNumber, getObjectStatus } from '../helpers/helpers';

import './SingleObject.scss';

function InterestForm({ objectId, status, propertyNumber }) {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [zip, setZip] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function postInterest() {
    // Use a cors proxy
    const url = `https://wallinbostad.herokuapp.com/api/v1/interest-signups`;

    setIsLoading(true);

    try {
      const formData = {
        fullName: `${firstname} ${lastname}`,
        phone,
        email,
        zip,
        projectName: 'Triolen',
        propertyNumber,
      };

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYXJzYXByaWwuc2UiLCJpYXQiOjE2MDc5NTc5OTR9.EYE_Ezzt4OMh5upCqDz6b7UjxEOI0oKTE8uYe8z0cRA`,
        },
      });
      const data = await response.json();

      setIsLoading(false);
      setSubmitted(true);

      return data;
    } catch (e) {
      console.log('Error', e);
      setIsLoading(false);
      setSubmitted(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    postInterest();
  }

  if (status !== 'Till salu') {
    return (
      <div className="InterestForm">
        <h2>Intresseanmälan</h2>
        <p>Just nu går det inte att anmäla intresse för detta objekt.</p>
      </div>
    );
  }

  if (submitted) {
    return (
      <div className="InterestForm-confirmation">
        <h3>Tack! Vi har mottagit din intresseanmälan.</h3>
      </div>
    );
  }

  return !isLoading ? (
    <form className="InterestForm" onSubmit={handleSubmit}>
      <h2>Intresseanmälan</h2>
      <ul className="InterestForm-fields">
        <li className="InterestForm-field-item">
          <label className="visually-hidden" htmlFor="firstname">
            Förnamn
          </label>
          <input
            type="text"
            name="firstname"
            value={firstname}
            maxLength="80"
            required
            placeholder="Förnamn*"
            onChange={(event) => {
              setFirstname(event.currentTarget.value);
            }}
          />
        </li>
        <li className="InterestForm-field-item">
          <label className="visually-hidden" htmlFor="lastname">
            Efternamn
          </label>
          <input
            type="text"
            name="lastname"
            value={lastname}
            maxLength="80"
            required
            placeholder="Efternamn*"
            onChange={(event) => {
              setLastname(event.currentTarget.value);
            }}
          />
        </li>
        <li className="InterestForm-field-item">
          <label className="visually-hidden" htmlFor="email">
            E-postadress
          </label>
          <input
            type="email"
            name="email"
            value={email}
            maxLength="120"
            required
            placeholder="E-postadress*"
            onChange={(event) => {
              setEmail(event.currentTarget.value);
            }}
          />
        </li>
        <li className="InterestForm-field-item">
          <label className="visually-hidden" htmlFor="phone">
            Telefon
          </label>
          <input
            type="text"
            name="phone"
            value={phone}
            maxLength="20"
            placeholder="Telefon"
            onChange={(event) => {
              setPhone(event.currentTarget.value);
            }}
          />
        </li>
        <li className="InterestForm-field-item">
          <label className="visually-hidden" htmlFor="zip">
            Postnummer
          </label>
          <input
            type="text"
            name="zip"
            value={zip}
            maxLength="20"
            placeholder="Postnummer"
            onChange={(event) => {
              setZip(event.currentTarget.value);
            }}
          />
        </li>
        <li className="InterestForm-field-item">
          <label>
            <input
              type="checkbox"
              checked={acceptedTerms}
              value="1"
              onChange={(event) => {
                setAcceptedTerms(!acceptedTerms);
              }}
            />
            Jag har tagit del av informationen om hur{' '}
            <a
              href="https://wallinbostad.se/om-personuppgiftsbehandling/"
              rel="noopener noreferrer"
              target="_blank"
            >
              mina personuppgifter
            </a>{' '}
            behandlas
          </label>
        </li>
      </ul>
      <footer className="InterestForm-footer">
        <button disabled={isLoading || !acceptedTerms}>Skicka in</button>
      </footer>
    </form>
  ) : (
    <div className="InterestForm">
      <LoadingIndicator />
    </div>
  );
}

export default function SingleObject(props) {
  const { objectId, items } = props;

  if (!items) {
    return null;
  }

  // The items inside object price list
  const object = [...items].find((item) => {
    return String(item.id) === objectId;
  });

  if (!object) {
    return <NoMatch />;
  }

  const price = object.price ? formatNumber(object.price) : null;
  const fee = object.monthlyFee ? formatNumber(object.monthlyFee) : null;
  const status = object.status ? getObjectStatus(object.status) : '-';

  const hasFactsheet = object.factsheetUrl && object.floorPlanUrl;

  return (
    <>
      <BackLink />
      <article className="SingleObject" data-testid="single-object">
        <h1 data-testid="single-object-apartmentnumber">{object.number}</h1>
        <div className="SingleObject-main">
          <ul className="SingleObject-summary">
            <li>{object.description}</li>
            <li>
              Status: <strong>{status}</strong>
            </li>
            <li>
              Antal rum: <strong>{object.rooms} rum & kök</strong>
            </li>
            <li>
              Pris: <strong>{price ? `${price} SEK` : '-'}</strong>
            </li>
            <li>
              Avgift: <strong>{fee ? `${fee} SEK` : '-'}</strong>
            </li>
            <li>
              Storlek: <strong>{object.size} m²</strong>
            </li>
            <li>
              Våning: <strong>{object.floor}</strong>
            </li>
            <li>
              Hus <strong>{object.number.charAt(0) || ''}</strong>
            </li>
          </ul>
        </div>
        <div className="floorplan">
          <h2>Bofaktablad</h2>
          {hasFactsheet && (
            <a
              href={object.factsheetUrl}
              target="_blank"
              rel="noopener noreferrer"
              title="Läs hela bofaktabladet"
            >
              <img
                src={object.floorPlanUrl}
                alt="Bofaktablad"
                className="floorplan-cover-img"
              />
              <span>Till bofaktabladet</span>
            </a>
          )}
          {!object.factsheetUrl && <p>-</p>}
        </div>
        <InterestForm
          objectId={objectId}
          status={status}
          propertyNumber={object.number}
        />
      </article>
    </>
  );
}
