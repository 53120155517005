import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ObjectItem from './ObjectItem';
import ObjectTable from './ObjectTable';
import { getSvgData, getSvgInactive } from '../helpers/helpers';

import './Scene.scss';

const NextSceneLink = ({ sceneIndex, building }) => {
  const sceneNumber = parseInt(sceneIndex, 10);
  const nextSceneIndex = sceneNumber !== 2 ? sceneNumber + 1 : 1;
  return (
    <Link
      to={`/hus/${building}/${nextSceneIndex}`}
      className="next-scene"
      title="Byt till nästa vy"
    >
      <svg className="icon icon-chevron-right">
        <use xlinkHref="#icon-chevron-right"></use>
      </svg>
    </Link>
  );
};

const PrevSceneLink = ({ sceneIndex, building }) => {
  const sceneNumber = parseInt(sceneIndex, 10);
  const prevSceneIndex = sceneNumber !== 1 ? sceneNumber - 1 : 2;
  return (
    <Link
      to={`/hus/${building}/${prevSceneIndex}`}
      className="prev-scene"
      title="Byt till föregående vy"
    >
      <svg className="icon icon-chevron-left">
        <use xlinkHref="#icon-chevron-left"></use>
      </svg>
    </Link>
  );
};

export function Scene({
  building,
  sceneIndex,
  items,
  svgData,
  handleActiceObject,
  activeObjectId,
  showStatus,
  svgInactive,
}) {
  // console.log('svg children', svgData.children);

  // Map all the objects into its corresponding SVG path in the render
  const objects = svgData.children.map((item) => {
    const object = [...items].find((o) => {
      if (!o) {
        return false;
      }

      return o.number === item.attributes['data-name'];
    });
    if (!object) {
      return null;
    } else {
      return (
        <ObjectItem
          id={object.id}
          key={object.id}
          name={item.name}
          type={item.type}
          attributes={item.attributes}
          itemData={object}
          handleActiceObject={handleActiceObject}
          activeObjectId={activeObjectId}
        />
      );
    }
  });

  const inactives = svgInactive
    ? svgInactive.children.map((item) => {
        return (
          <polygon
            className={'inactive-house'}
            points={item.attributes.points}
            data-testid="inactive-house-svg"
          ></polygon>
        );
      })
    : null;

  const imgSrc = `/images/${building}_${sceneIndex}.jpg`;

  return (
    <div
      className={showStatus ? 'show-status-for-objects Scene' : 'Scene'}
      data-testid="scene"
    >
      <svg
        viewBox={svgData.attributes.viewBox}
        className="scene-svg"
        preserveAspectRatio={
          svgData.attributes.preserveAspectRatio || 'xMidYMid'
        }
        data-testid="scene-svg"
      >
        {inactives}
        {objects}
      </svg>
      <img src={imgSrc} alt="Rendering" data-testid="scene-background" />
      <PrevSceneLink sceneIndex={sceneIndex} building={building} />
      <NextSceneLink sceneIndex={sceneIndex} building={building} />
    </div>
  );
}

export default function SceneContainer({ items }) {
  const [activeObjectId, setActiveObjectId] = useState(null);
  const [showStatus, setShowStatus] = useState(true);
  const { building, sceneIndex } = useParams();
  const svgData = getSvgData(sceneIndex, building);
  const svgInactive = getSvgInactive(sceneIndex, building);

  const handleActiveObject = (id) => {
    setActiveObjectId(id);
  };

  const objects = svgData.children
    .filter((item) => {
      return item.attributes.id || false;
    })
    .map((item) => {
      const object = [...items].find((o) => {
        return o.number === item.attributes['data-name'];
      });

      if (!object) {
        return null;
      } else {
        return object;
      }
    });

  const itemsInActiveBuilding = items.filter(({ number }) => {
    return String(number).charAt(0) === String(building);
  });

  return (
    <section className="camera-scene">
      <div className="scene-container" data-testid="scene-container">
        <Scene
          sceneIndex={sceneIndex}
          building={building}
          items={objects}
          svgData={svgData}
          handleActiceObject={handleActiveObject}
          activeObjectId={activeObjectId}
          showStatus={showStatus}
          svgInactive={svgInactive}
        />
      </div>
      <ObjectTable
        items={objects}
        handleActiceObject={handleActiveObject}
        statusState={{ showStatus, setShowStatus }}
        building={building}
        sceneIndex={sceneIndex}
        activeObjectId={activeObjectId}
        totalItems={itemsInActiveBuilding.length || items.length}
      />
    </section>
  );
}
