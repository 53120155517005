import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import Modal from 'react-modal';
import { useModal } from '../contexts/ModalContext';

import './ModalSceneNav.scss';
import hus1Img from '../assets/images/hus_1.svg';
import hus2Img from '../assets/images/hus_2.svg';
import hus3Img from '../assets/images/hus_3.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '40px',
    transform: 'translate(-50%, -50%)',
  },
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default function ModalSceneNav({ building = 1 }) {
  const className = `scene-menu scene-menu-${building}`;

  const { isOpen, setIsOpen } = useModal();

  function closeModal() {
    setIsOpen(false);
  }

  const routeMatch1 = useRouteMatch(`/hus/${building}/1`);
  const routeMatch2 = useRouteMatch(`/hus/${building}/2`);

  let buildImgSrc = '';

  if (building === 1) {
    buildImgSrc = hus1Img;
  } else if (building === 2) {
    buildImgSrc = hus2Img;
  } else if (building === 3) {
    buildImgSrc = hus3Img;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        contentLabel="Välj vy"
      >
        <div className="birdview">
          <ul className={className}>
            <li
              className={
                routeMatch1
                  ? 'scene-menu-item active-camera'
                  : 'scene-menu-item'
              }
            >
              <NavLink to={`/hus/${building}/1`} data-sceneIndex="1"></NavLink>
            </li>
            <li
              className={
                routeMatch2
                  ? 'scene-menu-item active-camera'
                  : 'scene-menu-item'
              }
            >
              <NavLink to={`/hus/${building}/2`} data-sceneIndex="2"></NavLink>
            </li>
          </ul>
          <img src={buildImgSrc} alt="Fågelvy av husen" />
        </div>
      </Modal>
    </>
  );
}
