import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Buildings.scss';

// The current static svg item path elemeents and render image
import image from '../views/building.jpg';
import buildings from '../views/husen.json';

export default function Buildings() {
  const { attributes, children } = buildings;

  const [currentBuilding, setCurrentBuilding] = useState(null);

  // Map all the objects into its corresponding SVG path in the render
  const buildingPolygons = children.map((item, index) => {
    const { id, d } = item.attributes;
    const houseAndIndex = id.split('_');
    const houseIndex = houseAndIndex[1];
    return (
      <Link
        to={`/hus/${houseIndex}/2`}
        data-testid={`building-link`}
        key={index}
        onMouseEnter={() => {
          setCurrentBuilding(id);
        }}
        onMouseLeave={() => {
          setCurrentBuilding(null);
        }}
        className={currentBuilding === id ? 'active' : null}
      >
        <path id={id} d={d} data-testid="building-svg"></path>
      </Link>
    );
  });

  return (
    <div className="buildings-wrapper">
      <div className="Buildings">
        <svg
          data-testid="buildings-svg"
          viewBox={attributes.viewBox}
          preserveAspectRatio={attributes.preserveAspectRatio || 'xMidYMid'}
        >
          {buildingPolygons}
        </svg>
        <img src={image} alt="Rendering" data-testid="render-view-image" />
      </div>
      <ul className="buildings-list">
        <li>Välj hus:</li>
        <li>
          <Link
            to="/hus/1/2"
            className={currentBuilding === 'hus_1' ? 'active' : null}
            onMouseEnter={() => {
              setCurrentBuilding('hus_1');
            }}
            onMouseLeave={() => {
              setCurrentBuilding(null);
            }}
          >
            Hus 1
          </Link>
        </li>
        <li>
          <Link
            to="/hus/2/2"
            className={currentBuilding === 'hus_2' ? 'active' : null}
            onMouseEnter={() => {
              setCurrentBuilding('hus_2');
            }}
            onMouseLeave={() => {
              setCurrentBuilding(null);
            }}
          >
            Hus 2
          </Link>
        </li>
        <li>
          <Link
            to="/hus/3/2"
            className={currentBuilding === 'hus_3' ? 'active' : null}
            onMouseEnter={() => {
              setCurrentBuilding('hus_3');
            }}
            onMouseLeave={() => {
              setCurrentBuilding(null);
            }}
          >
            Hus 3
          </Link>
        </li>
      </ul>
    </div>
  );
}
