import hus_1_1 from '../views/hus_1_1.json';
import hus_1_2 from '../views/hus_1_2.json';
import hus_2_1 from '../views/hus_2_1.json';
import hus_2_2 from '../views/hus_2_2.json';
import hus_3_1 from '../views/hus_3_1.json';
import hus_3_2 from '../views/hus_3_2.json';

import inactive_1_1 from '../views/inactive_1_1.json';
import inactive_2_2 from '../views/inactive_2_2.json';
import inactive_2_1 from '../views/inactive_2_1.json';
import inactive_3_2 from '../views/inactive_3_2.json';

export const loadObjects = async () => {
  // const corsProxy = 'https://dry-dawn-83390.herokuapp.com/';
  const url = `https://wallinbostad.herokuapp.com/api/v1/projects/1`;

  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Origin: window.location.origin,
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYXJzYXByaWwuc2UiLCJpYXQiOjE2MDc5NTc5OTR9.EYE_Ezzt4OMh5upCqDz6b7UjxEOI0oKTE8uYe8z0cRA`,
      },
    });
    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error(error);
  }
};

export function getObjectStatus(status = '') {
  if (status === 'AVAILABLE') {
    return 'Till salu';
  } else if (status === 'BOOKED') {
    return 'Bokad';
  } else if (status === 'SOLD') {
    return 'Såld';
  } else {
    return status;
  }
}

export const getSvgData = (scene = 0, building = 0) => {
  const index = parseInt(scene, 10);
  const buildingIndex = parseInt(building, 10);
  if (buildingIndex === 1) {
    if (index === 1) {
      return hus_1_1;
    } else if (index === 2) {
      return hus_1_2;
    } else {
      return hus_1_1;
    }
  } else if (buildingIndex === 2) {
    if (index === 1) {
      return hus_2_1;
    } else if (index === 2) {
      return hus_2_2;
    } else {
      return hus_2_1;
    }
  } else if (buildingIndex === 3) {
    if (index === 1) {
      return hus_3_1;
    } else if (index === 2) {
      return hus_3_2;
    } else {
      return hus_3_1;
    }
  } else {
    return false;
  }
};

export const getSvgInactive = (scene = 0, building = 0) => {
  const index = parseInt(scene, 10);
  const buildingIndex = parseInt(building, 10);
  if (buildingIndex === 1 && index === 1) {
    return inactive_1_1;
  } else if (buildingIndex === 2) {
    if (index === 1) {
      return inactive_2_1;
    } else if (index === 2) {
      return inactive_2_2;
    }
  } else if (buildingIndex === 3 && index === 2) {
    return inactive_3_2;
  }

  return null;
};

export function formatNumber(number) {
  return parseInt(number, 10).toLocaleString('fr');
}
