import React from 'react';
import { useRouteMatch, NavLink } from 'react-router-dom';
import ModalSceneNav from './ModalSceneNav';

import './Navigation.scss';
import allowedRoutes from '../data/allowedRoutes.json';

export default function Navigation() {
  const { buildings, scenes } = allowedRoutes;

  const routes = buildings.map((building) => {
    return scenes.map((scene) => {
      return `/hus/${building}/${scene}`;
    });
  });

  // Route matching for the buildings & scenes
  const matchIndex = useRouteMatch({
    path: '/',
    exact: true,
  });
  const matchHouse1 = useRouteMatch(routes[0]);
  const matchHouse2 = useRouteMatch(routes[1]);
  const matchHouse3 = useRouteMatch(routes[2]);

  if (matchIndex) {
    return null;
  }

  return (
    <nav>
      <ul className="main-nav">
        <li
          className={
            matchHouse1 ? 'main-nav-item active-building' : 'main-nav-item'
          }
        >
          <NavLink to="/hus/1/2/">Hus 1</NavLink>
        </li>
        <li
          className={
            matchHouse2 ? 'main-nav-item active-building' : 'main-nav-item'
          }
        >
          <NavLink to="/hus/2/2/">Hus 2</NavLink>
        </li>
        <li
          className={
            matchHouse3 ? 'main-nav-item active-building' : 'main-nav-item'
          }
        >
          <NavLink to="/hus/3/2/">Hus 3</NavLink>
        </li>
        <li className="main-nav-item">
          <NavLink to="/prislista/">Prislista</NavLink>
        </li>
      </ul>
      {matchHouse1 && <ModalSceneNav building={1} />}
      {matchHouse2 && <ModalSceneNav building={2} />}
      {matchHouse3 && <ModalSceneNav building={3} />}
    </nav>
  );
}
